<template>
    <div class="workplace-policy">
        <div class="wrap-header">
            <el-tabs
                v-model="tabInfo.active"
                @tab-click="clickTab"
            >
                <el-tab-pane
                    v-for="(item,index) in cpu_tabList"
                    :key="index"
                    :name="item.name"
                >
                    <template v-slot:label>
                        <p class="tabItem-label">
                            <span>{{item.label}}</span>
                            <span
                                v-if="item.count"
                                class="tabItem-count"
                            >{{item.count}}</span>
                        </p>
                    </template>
                </el-tab-pane>
            </el-tabs>
            <span class="header-count">共 {{policyTotal.total}} 个政策推送</span>
        </div>
        <div
            class="wrap-content"
            v-loading="dataLoading"
        >
            <div
                v-for="(item, index) in policyInfo.data"
                :key="index"
                class="pad-2 message-cell"
                @click="toPolicyGuide(item)"
            >
                <div class="policy_top">
                    <div class="policyItem-title">
                        <span class="title-name">{{item.name}}</span>
                        <!-- <p>最高补助金额：{{item.zzxe?item.zzxe+'万元':'无'}}</p> -->
                    </div>
                    <div class="policyItem-info">
                        <div class="info-item">
                            <label class="info-label">受理部门：</label>
                            <div class="info-value">{{item.deptname}}</div>
                        </div>
                        <div class="info-item">
                            <label class="info-label">申报时间：</label>
                            <div class="info-value">
                                {{cpu_formatDate(item.starttime)}}
                                ~
                                <span>{{cpu_formatDate(item.endtime)}}</span>
                            </div>
                        </div>
                        <div class="info-item info-ppd">
                            <label class="info-label">匹配度：</label>
                            <div class="info-value">
                                <el-rate
                                    v-model="item.star"
                                    disabled
                                ></el-rate>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="policy_bottom">
                    <div
                        v-if="item.zzbzData"
                        class="policy-zzbz"
                    >
                        <div class="zzbz-title">
                            <span style="margin-right: 20px;">资助标准</span>
                            <el-button
                                type="text"
                                @click.stop="toggleZzbzFold(item)"
                            >
                                {{item.zzbzUnfold?'收起':'展开'}}
                                <i class="el-icon-caret-bottom"></i>
                            </el-button>
                        </div>
                        <div v-show="item.zzbzUnfold">
                            <div
                                v-for="(zzbzItem,zIndex) in item.zzbzData"
                                :key="'zzbz'+zIndex"
                            >
                                {{zIndex+1}}、{{zzbzItem.zzqx}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NoDataView :dataState="cpu_noData"></NoDataView>
            <el-pagination
                v-if="!!policyInfo.data.length&&tabInfo.active==1"
                class="content-pagination"
                style="margin-top: 20px;"
                hide-on-single-page
                :total="pageInfo.total"
                :current-page.sync="pageInfo.num"
                background
                layout="total,prev,pager,next,jumper"
                @current-change="changePage"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { FormSelect } from '@/components/form/index.js'
import NoDataView from '@/components/nodata-view'
import { formatDate } from '@/utils/dateHandler.js'

const NOW = new Date()

export default {
    components: {
        // FormSelect,
        NoDataView,
    },
    data() {
        return {
            tabInfo: {
                active: '1',
            },
            // 搜索
            searchInfo: {
                data: {
                },
                typeList: [
                    { value: '1', label: '系统推荐' },
                    // { value: '2', label: '区领导推荐' },
                    // { value: '3', label: '部门推荐' },
                    // { value: '4', label: '服企专员推荐' },
                    // { value: '5', label: '高管推荐' },
                ],
            },
            // 政策信息
            policyInfo: {
                data: [],
            },
            // 政策推送统计
            policyTotal: {
                total: 0,
                sysnum: 0, // 系统推荐
                qldnum: 0, // 区领导推荐
                bmnum: 0, // 部门推荐
                qfnum: 0, // 服企专员推荐
                ggnum: 0, // 高管推荐
            },
            dataLoading: false, // 数据加载状态位
            // 分页
            pageInfo: {
                total: 0,
                num: 1,
                size: 5,
            },
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo",
            getDictLabel: 'dictionary/getDictLabel',
        }),
        cpu_tabList() {
            return [
                { name: '1', label: '系统推荐', count: this.policyTotal.sysnum },
                // { name: '2', label: '区领导推荐', count: this.policyTotal.qldnum },
                // { name: '3', label: '部门推荐', count: this.policyTotal.bmnum },
                // { name: '4', label: '服企专员推荐', count: this.policyTotal.qfnum },
                // { name: '5', label: '高管推荐', count: this.policyTotal.ggnum },
            ]
        },
        cpu_formatDate() {
            return function (date) {
                return formatDate(date, 'date') || '无'
            }
        },
        /**
         * @description: 暂无数据状态位
         * @return {String}
         */
        cpu_noData() {
            if (!this.dataLoading && !this.policyInfo.data.length) {
                return 'noData'
            }
            return ''
        },
    },
    created() {
        // this.$store.dispatch('dictionary/commonSetDic', {
        //     types: ['sq_policy_zjlx']
        // })

        this.getPolicyBySystem()
        // this.getPolicyTotal()
    },
    methods: {
        /**
         * @description 点击tab
         */
        clickTab() {
            this.policyInfo.data = []

            if (this.tabInfo.active == 1) {
                this.getPolicyBySystem(1, this.pageInfo.size)
            } else {
                this.getPolicyList()
            }
            this.getPolicyTotal()
        },
        toPolicyGuide(item) {
            /**
             * 20210419
             * 参考 政策类 点击前往政策项目详情，这边也做一个跳转
             */
            this.$store.commit('updateCurrentGuideId', item.sxid)
            const { href } = this.$router.resolve({
                path: '/guideSuqiu',
                query: {
                    id: item.sxid,
                    name: item.name,
                },
            })
            window.open(href, '_blank')
            // this.$router.push({
            //     path: "/guideSuqiu",
            //     query: {
            //         name: item.name,
            //         itemType: item.itemtype || item.type,
            //         isAdded: item.isAdded || item.isread,
            //     },
            // });
        },
        /**
         * @description 切换资助标准展开收起状态
         */
        toggleZzbzFold(item) {
            if (!Reflect.has(item, 'zzbzUnfold')) {
                this.$set(item, 'zzbzUnfold', true)
            } else {
                this.$set(item, 'zzbzUnfold', !item.zzbzUnfold)
            }
        },
        /**
         * @description 切换页码
         * @param {Number} pageNum 当前页码
         */
        changePage(pageNum) {
            this.policyInfo.data = []

            this.getPolicyBySystem(pageNum, this.pageInfo.size)

            // 滚动到页面顶部
            let animate = function () {
                let scrollTop = document.documentElement.scrollTop - 50
                document.documentElement.scrollTop = scrollTop < 0 ? 0 : scrollTop
                if (0 < document.documentElement.scrollTop) {
                    window.requestAnimationFrame(animate)
                }
            }
            window.requestAnimationFrame(animate)
        },
        /**
         * @description 查询系统推荐政策
         * @param {Number} pageNum 页码
         * @param {Number} pageSize 单页记录数
         */
        getPolicyBySystem(pageNum, pageSize) {
            pageNum && (this.pageInfo.num = pageNum)
            pageSize && (this.pageInfo.size = pageSize)

            let url = '/dev-api/policy/evaluation/recommend'
            let params = {
                shxydm: this.userInfo.tyshxydm,
                pageNum: this.pageInfo.num,
                pageSize: this.pageInfo.size,
            }

            this.$httpApi.get(url, params).then(res => {
                console.log('getPolicyBySystem:', res)

                if (res.code == 200) {
                    this.pageInfo.total = res.total || 0
                    /**
                     * 20210628
                     * 2个人写了2个接口，返回的数据格式不一样
                     * 用map同步一下
                     */
                    this.policyInfo.data = (res.rows || []).map(cv => {
                        return {
                            name: cv.xmName,
                            deptname: cv.deptName,
                            starttime: cv.startTime,
                            endtime: cv.endTime,
                            policyId: cv.policyId,
                            sxid: cv.xmId,
                            star: cv.star,
                            zzbzData: [
                                { zzqx: cv.supportStandard },
                            ],
                        }
                    })
                    for (let i = 0; i < this.policyInfo.data.length; i++) {
                        this.runPolicyStarCalculate(this.policyInfo.data[i])
                    }
                }
            }).catch(err => {
                console.log('getPolicyBySystem error:', err)
            })
        },
        /**
         * @description 查询政策列表
         * @param {Number} page 页码
         * @param {Number} size 每页记录数
         */
        getPolicyList(page = 1, size = 10) {
            let url = '/dev-api/sqzdManager/data/find/sq_policypushenterprise_query'
            /**
             * 20210419
             * TODO
             * pc端登录逻辑后面会改动，先写死userID走通业务
             */
            let params = {
                receiveid: this.userInfo.userId,
                resource: this.tabInfo.active,
                // type: this.tabInfo.active,
            }
            this.dataLoading = true
            this.$httpApi.post(url, params).then(res => {
                console.log('getPolicyList:', res)
                this.dataLoading = false

                this.policyInfo.data = res.data || []
                /**
                 * 20210508
                 * 增加 星级匹配度/最高补助金额数
                 * 目前提供的接口设计为 获取单个政策的匹配度和补助金额
                 * 列表会发N个请求，暂时遗留这个性能问题
                 * 
                 * 20210628
                 * 增加 资助标准
                 */
                for (let i = 0; i < this.policyInfo.data.length; i++) {
                    if (this.policyInfo.data[i].tyshxydm && this.policyInfo.data[i].tyshxydm !== 'null') {
                        this.getPolicyRate(this.policyInfo.data[i])
                    } else {
                        this.$set(this.policyInfo.data[i], 'star', 4)
                        this.$set(this.policyInfo.data[i], 'zzxe', 500)
                    }
                    this.getPolicyZzbz(this.policyInfo.data[i])
                }
            }).catch(err => {
                console.log('getPolicyList error:', err)
                this.dataLoading = false
            })
        },
        /**
         * @description 查询系统推荐政策的星级匹配度
         * @param {Object} policy 政策
         */
        runPolicyStarCalculate(policy) {
            let url = '/dev-api/policy/evaluation/generate'
            let params = {
                policyId: policy.policyId,
                sxid: policy.xmId,
            }

            this.$httpApi.get(url, params).then(res => {
                // console.log('runPolicyStarCalculate:', res)
            }).catch(err => {
                console.log('runPolicyStarCalculate error:', err)
            })
        },
        /**
         * @description: 获取政策的匹配度和补助金额
         * @param {Object} policy 政策
         */
        getPolicyRate(policy) {
            let url = ''
            if (policy.pusherid === 'system') {
                url = '/dev-api/sqzdManager/data/find/sq_policypushenterprise_start_query'
            } else {
                url = '/dev-api/sqzdManager/data/find/sq_policy_matchrate_query'
            }
            let params = {
                tyshxydm: policy.tyshxydm,
                zcid: policy.sxid,
            }
            this.$httpApi.post(url, params).then(res => {
                console.log('queryPolicyPiPei:', res)
                if (res.data && !!res.data.length) {
                    // 可能有多种匹配标准，取最高的展示
                    let ratenum = Number(res.data[0].ratenum || 0)
                    let zzxe = Number(res.data[0].zzxe || 0)
                    for (let i = 1; i < res.data.length; i++) {
                        if (res.data[i].ratenum && ratenum <= Number(res.data[i].ratenum)) {
                            if (res.data[i].zzxe && zzxe <= Number(res.data[i].zzxe)) {
                                ratenum = Number(res.data[i].ratenum || 0)
                                zzxe = Number(res.data[i].zzxe || 0)
                            }
                        }
                    }
                    this.$set(policy, 'star', (ratenum * 5) || 4)
                    this.$set(policy, 'zzxe', zzxe || 500)
                    this.$forceUpdate()
                }
            }).catch(err => {
                console.log('queryPolicyPiPei error:', err)
            })
        },
        /**
         * @description 查询政策资助标准
         * @param {Object} policy 政策
         */
        getPolicyZzbz(policy) {
            let url = '/dev-api/sqzdManager/data/find/sq_policy_project_zzqx_list'
            let params = { sxids: policy.sxid }

            this.$httpApi.post(url, params).then(res => {
                // console.log('getPolicyZzbz:', res)
                if (res.data && !!res.data.length) {
                    this.$set(policy, 'zzbzData', res.data)
                }
            }).catch(err => {
                console.log('getPolicyZzbz error:', err)
            })
        },
        /**
         * @description: 查询推荐政策的统计
         */
        getPolicyTotal() {
            let url = '/dev-api/sqzdManager/data/find/sq_policy_pushstatbyreceiveidunrepeat_query'
            let params = {
                userid: this.userInfo.userId,
            }

            this.policyTotal = {
                total: 0,
                sysnum: 0, // 系统推荐
                qldnum: 0, // 区领导推荐
                bmnum: 0, // 部门推荐
                qfnum: 0, // 服企专员推荐
                ggnum: 0, // 高管推荐
            }
            this.$httpApi.post(url, params).then(res => {
                // console.log('getPolicyTotal:', res)

                if (res.data && !!res.data.length) {
                    for (let i = 0; i < res.data.length; i++) {
                        this.policyTotal[res.data[i].type] += res.data[i].total
                    }
                }
            }).catch(err => {
                console.log('getPolicyTotal error:', err)
            })
            /**
             * 20210628
             * 系统推荐由李兴隆李工提供了单独的接口
             * 统计数目也单独计算 系统推荐 的，然后再合并进去
             */
            let sysUrl = '/dev-api/policy/evaluation/recommend'
            let sysParams = {
                shxydm: this.userInfo.tyshxydm,
                pageNum: 1,
                pageSize: 1,
            }

            this.$httpApi.get(sysUrl, sysParams).then(res => {
                if (res.code == 200) {
                    this.policyTotal.total += res.total ?? 0
                    this.policyTotal.sysnum = res.total ?? 0
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

$tabColors: (
    "0": $col-blue,
    "1": $col-yellow,
    "2": $col-orange,
    "3": $col-red,
    "4": $col-green,
);

.workplace-policy {
    width: 100%;
    height: 100%;
}
.wrap-header {
    position: relative;
    margin-bottom: 10px;
    background-color: #ffffff;
}
::v-deep .el-tabs__header {
    padding-top: 5px;

    .el-tabs__nav-wrap {
        .el-tabs__item {
            padding-right: 20px;
            padding-left: 20px;
            font-size: 16px;
            font-weight: bold;

            .tabItem-label {
                position: relative;
                display: -webkit-flex;
                display: flex;
                align-items: center;

                .tabItem-count {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    display: inline-block;
                    min-width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    margin-left: 5px;
                    line-height: 16px;
                    text-align: center;
                    font-size: 12px;
                    color: #ffffff;
                    background-color: $col-red;
                    -webkit-transform: translate(calc(100% + 2px), -50%);
                    -moz-transform: translate(calc(100% + 2px), -50%);
                    -o-transform: translate(calc(100% + 2px), -50%);
                    -ms-transform: translate(calc(100% + 2px), -50%);
                    transform: translate(calc(100% + 2px), -50%);
                }
                @each $k, $v in $tabColors {
                    &.tabItem-label-#{$k} .tabItem-count {
                        background-color: $v;
                    }
                }
            }
            &:hover,
            &.is-active {
                color: $col-theme;

                @each $k, $v in $tabColors {
                    .tabItem-label-#{$k} {
                        color: $v;
                    }
                }
            }
        }
        .el-tabs__active-bar {
            background-color: $col-theme;
        }

        &::after {
            display: none;
        }
    }
}
.header-count {
    position: absolute;
    top: 50%;
    right: 20px;
    font-family: Microsoft YaHei;
    color: #828282;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.wrap-content {
    position: relative;
    min-height: calc(100% - 70px);
}
.message-cell {
    position: relative;
    background-color: #ffffff;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    cursor: pointer;

    .policy_top {
        border-bottom: 1px solid $col-separator;
    }

    .policyItem-title {
        .title-name {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }
        ::v-deep .el-rate {
            margin-left: 20px;
        }
        ::v-deep .el-rate {
            margin-right: 20px;
        }
    }
    .policyItem-info {
        display: -webkit-flex;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: -40px;

        .info-item {
            display: -webkit-flex;
            display: flex;
            margin-left: 40px;
            line-height: 3;

            .info-label {
                color: $col-theme;
            }

            .info-value {
                flex: 1;
                color: #000000;
            }
        }
        .info-ppd .info-value {
            display: -webkit-flex;
            display: flex;
            align-items: center;
        }
    }

    &:hover {
        box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
        -webkit-transform: translateY(-2px);
        -moz-transform: translateY(-2px);
        -o-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        transform: translateY(-2px);
    }
}

.message-cell + .message-cell {
    margin-top: 5px;
}
::v-deep .content-pagination {
    text-align: center;
}
</style>
